.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  margin: auto;
}

.preview-qrcode-container{
  font-size: 14px;
  text-align: center;
}

.preview-vm-qrcode{
  display: flex;
  justify-content: center;
  height: 100%;
}

.switch-title{
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.switch-code {
  font-size: 21px;
  font-weight: 600;
}

.expiry-date {
  margin-top: 30px;
}

.vm-container {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.hide {
  display: none;
}

.c-audio {
  margin-top: 20px;
  position: relative;
}

.a-controls {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border-radius: 50px;
}

#waveform {
  padding: 20px;
}

.c-video {
  margin-top: 20px;
  width: 100%;
  max-width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  position: relative;
  overflow: hidden;
}

.c-video iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
}

.c-video video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
}

.c-video:hover .v-controls {
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.v-controls{
  color: #777;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #f5f5f5;
  transform: matrix(1, 0, 0, 1, 0, 52.2);
  transition: all 0.4s;
}

.teal-bar {
  height: 10px;
  top: 0;
  left: 0;
  width: 100%;
}

#seek-slider {
  -webkit-appearance: none;
  height: 10px;
  border: none;
}

.time {
  padding: 10px;
  flex: 1;
  text-align: left;
}

#a-current{
  padding-left: 20px;
}

.play-pause button , .vol-button button , .a-vol button , .fullscreen button , .download button {
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.play-pause {
  padding-top: 5px;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.play-pause button::before , .play-pause button.play::before {
  content: url('https://cdn.shopify.com/s/files/1/1428/2106/t/5/assets/play-button.svg?v=2206608396854330351');
}

.play-pause button.pause::before {
  content: url('https://cdn.shopify.com/s/files/1/1428/2106/t/5/assets/pause-button.svg?v=7485777385855759475');
}

.download button::before , .a-download button::before {
  content: url('https://cdn.shopify.com/s/files/1/1428/2106/t/5/assets/download-button.svg?v=35773428140899117901642061031');
}

.volume {
  flex: 1;
  justify-content: flex-end;
  text-align: right;
}

.vol-button , .a-vol {
  position: absolute;
  right: 5%;
  padding: 10px;
}

.download, .a-download{
  position: absolute;
  right: 12%;
  padding: 7px;
}

.a-download{
  right: 15%;
}

.vol-button button::before , .a-vol button::before {
  content: url('https://cdn.shopify.com/s/files/1/1428/2106/t/5/assets/volume.svg?v=7123561982705323290');
}

.vol-button button.mute::before {
  content: url('https://cdn.shopify.com/s/files/1/1428/2106/t/5/assets/vol-mute.svg?62378');
}

#vol-slider , #a-slider {
  position: absolute;
	height: 200%;
	width: 10px;
	background: #f5f5f5;
	border-radius: 15px;
  bottom: 100%;
  left: 35%;
  visibility: hidden;
  border: none;
  z-index: 100;
}

.ui-slider-range-min {
  height: 200%;
  width: 10px;
  position: absolute;
  background: #81d8d0 !important;
  border: none;
  border-radius: 10px;
}

.ui-slider-handle  {
  visibility: hidden;
}

.fullscreen {
  flex: 1;
  justify-content: flex-end;
  text-align: right;
  height: 40px;
}

#fullscreen {
  padding: 10px;
}

.fullscreen button::before {
  content: url('https://cdn.shopify.com/s/files/1/1428/2106/t/5/assets/fullscreen.svg?v=13976325279884471823');
}

.message-container{
    border: 1px solid #81d8d0;
    padding: 20px 15px;
    font-family: 'Kite One', sans-serif;
    color: #545454;
    font-size: 12px;
    margin: 0.5rem 50px;
}

.message{
  white-space: pre-line;
  text-align: justify;
  padding: 15px;
  color: black;
  display: inline-block;
  max-width: 80%;
}

.msg{
  text-align: center;
	color: #81d8d0;
  font-family: "Raleway";
  }

h1 {
  font-family: "Varela Round";
  text-align: center;
  color: #81d8d0;
}

.text{
  padding: 15px;
  color: black;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #7f35ff !important;
  border-color: #7f35ff !important;
}


@media (max-width: 768px){
  .vm-container{
    display: block;
    width: 100%
  }
  .c-audio{
    width: 100%;
  }
  .c-video {
    width: unset;
    background: unset;
    height: unset;
  }
  .vol-button {
    visibility: hidden;
  }
  .a-vol{
    visibility: hidden;
  }
  #a-current{
    padding-left: 10px;
  }
  .message-container{
    margin: 0.5rem 0;
  }
  .message{
    max-width: 100%;
  }
  .a-download{
   right: 6%
  }
}
